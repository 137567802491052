<template>
    <div>
        <ViewTitle :title="`業績查詢`" />
        <PerformanceFilter
            :listData="listData"
            :isLoading="isLoading"
            @show-data-table="showDataTable"
            v-model:filterPayloadProp="filterPayload"
            v-model:filterLogProp="filterLog"
        />
        <template v-if="isMounted">
            <SearchCondition
                :dataList="searchConditionList"
                :isHasListData="listData.length > 0"
                :isLoading="isLoading"
            />
        </template>
        <PerformanceTable
            :inoCounts="inoCounts"
            :listData="listData"
            :filterLog="filterLog"
            :isLoading="isLoading"
        />
        <template v-if="isMounted">
            <WaitingModal
                v-model="isShowWaitingModal"
                :isLoading="isLoading"
                :isSuccess="!isLoading"
            />
        </template>
    </div>
</template>

<script>
import ViewTitle from '@/components/ViewTitle.vue'
import PerformanceFilter from '@/containers/performance/PerformanceFilter.vue'
import PerformanceTable from '@/containers/performance/PerformanceTable.vue'
import {
    getPerformanceListAPI,
    getPerformancePersonalDetailsAPI
} from '@/assets/javascripts/api'
import { lazyLoadComponent } from '@/assets/javascripts/util'
const WaitingModal = lazyLoadComponent('containers/performance/WaitingModal')
const SearchCondition = lazyLoadComponent('components/SearchCondition')

export default {
    name: 'Performance',
    components: {
        ViewTitle,
        SearchCondition,
        PerformanceFilter,
        PerformanceTable,
        WaitingModal
    },
    methods: {
        showDataTable: async function () {
            if (!this.filterPayload.manCode) return
            let res
            try {
                this.isLoading = true
                this.isShowWaitingModal = true
                if (this.filterPayload.relationMode === 'self') {
                    res = await this.$getResponse(
                        this.filterPayload,
                        {
                            apiAlias: 'personalList',
                            apiName: '業績明細'
                        },
                        getPerformancePersonalDetailsAPI
                    )
                    this.inoCounts = res.data.inoCounts ?? {}
                } else {
                    res = await this.$getResponse(
                        this.filterPayload,
                        {
                            apiAlias: 'orgList',
                            apiName: '組織業績明細'
                        },
                        getPerformanceListAPI
                    )
                }
                this.listData = res.data.dataSource ?? []
            } catch (error) {
                this.isShowWaitingModal = false
                this.listData = []
            } finally {
                this.isLoading = false
            }
        }
    },
    computed: {
        dataTypeLabel: function () {
            return this.filterLog?.dataType?.label + '業績'
        },
        relationModeLabel: function () {
            return this.filterLog.relationMode?.label
        },
        searchConditionList: function () {
            const dataType = this.filterLog?.dataType?.value
            let dateItem = []
            const receive = {
                title: '受理日區間',
                value: `${this.filterLog?.receiveDateStart?.label || '-'} 至 ${
                    this.filterLog?.receiveDateEnd?.label || '-'
                }`
            }
            const commit = {
                title: '核實月區間',
                value: `${this.filterLog?.commitDateStart?.label || '-'} 至 ${
                    this.filterLog?.commitDateEnd?.label || '-'
                }`
            }
            const effect = {
                title: '生效日區間',
                value: `${this.filterLog?.effectDateStart?.label || '-'} 至 ${
                    this.filterLog?.effectDateEnd?.label || '-'
                }`
            }
            const isAudit = {
                title: '審核狀態',
                value: this.filterLog?.isAudit?.label || '-'
            }

            const relationMode = {
                title: '組織種類',
                value: this.relationModeLabel
            }

            if (dataType === 1) {
                dateItem = [receive, relationMode, isAudit]
            } else if (dataType === 2) {
                dateItem = [commit, relationMode]
            } else {
                dateItem = [receive, effect, commit, relationMode]
            }
            return dateItem
        }
    },
    watch: {},
    data() {
        return {
            isShowWaitingModal: false,
            isLoading: false,
            inoCounts: {},
            listData: [],
            filterPayload: {
                manCode: '',
                dataType: '',
                startDate: '',
                endDate: '',
                relationMode: '',
                supplierCode: '',
                payWay: '',
                customType: '',
                jobTypes: []
            },
            filterLog: {},
            isMounted: false
        }
    },
    mounted() {
        this.isMounted = true
    }
}
</script>

<style lang="scss" scoped>
.table-remark {
    display: none;
    justify-content: space-between;
    width: 100%;
    color: $secondary-grey;
    font-size: 14px;
    margin-bottom: 15px;

    @media screen and (max-width: 576px) {
        display: flex;
    }
}
</style>
